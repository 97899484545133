<template>
  <div class="page-container register">
    <div class="page-header">
      <ace-header show-back :back-func="onBack">
        {{ $t('system.login.registerButton') }}
      </ace-header>
    </div>
    <div class="page-body">
      <van-form
        ref="form"
        validate-first
        :show-error-message="false"
        validate-trigger="onSubmit"
        @submit="onSubmit"
        @failed="onFailed"
      >
        <div class="mx-4px">
          <van-field
            v-model="formData.email"
            clearable
            maxlength="50"
            autocomplete="off"
            name="email"
            :placeholder="$t('system.login.email')"
            :rules="[{ pattern: emailReg, message: $t('system.login.emailValidateMsg') }]"
            class="mt-12px"
          />
          <van-field
            v-model="formData.var_code"
            type="digit"
            autocomplete="off"
            clearable
            maxlength="6"
            name="var_code"
            :placeholder="$t('system.login.verifyCode')"
            :rules="[{ required: true, message: $t('system.login.verifyValidateMsg') }]"
            class="mt-12px"
          >
            <template #button>
              <van-button
                size="mini"
                type="info"
                native-type="button"
                :disabled="isStart"
                @click="onSend"
              >
                {{ getButtonText }}
              </van-button>
            </template>
          </van-field>
          <van-field
            v-model="formData.password"
            :type="showPwd ? 'text' : 'password'"
            clearable
            maxlength="30"
            autocomplete="off"
            name="password"
            :placeholder="$t('system.login.password')"
            :rules="[{ pattern: pwdReg, message: $t('system.login.passwordValidateMsg') }]"
            class="mt-12px"
          >
            <template #right-icon>
              <van-icon
                :name="showPwd ? 'eye' : 'closed-eye'"
                size="20px"
                @click="showPwd = !showPwd"
              />
            </template>
          </van-field>
          <van-field
            v-model="formData.repeat_password"
            :type="showRepeatPwd ? 'text' : 'password'"
            clearable
            maxlength="30"
            autocomplete="off"
            name="repeat_password"
            :placeholder="$t('system.login.repeatPassword')"
            :rules="[{ validator: validateRepeatPwd }]"
            class="mt-12px"
          >
            <template #right-icon>
              <van-icon
                :name="showRepeatPwd ? 'eye' : 'closed-eye'"
                size="20px"
                @click="showRepeatPwd = !showRepeatPwd"
              />
            </template>
          </van-field>
          <van-field name="policy" :border="false" class="mt-20px">
            <template #input>
              <van-checkbox
                v-model="formData.policy"
                icon-size="16px"
                checked-color="#ffd461"
                shape="square"
              >
                {{ $t('system.login.policyCheck') }}
              </van-checkbox>
              <router-link :to="privacyPath" target="_blank" class="text-primary">
                {{ $t('system.login.policy') }}
              </router-link>
            </template>
          </van-field>
          <div class="mt-30px mx-14px">
            <van-button
              block
              type="primary"
              :disabled="disabled"
              :loading="loading"
              native-type="submit"
            >
              {{ $t('system.login.freeAccount') }}
            </van-button>
          </div>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import { userRegister, sendEmail } from '@/api/user';

  export default {
    name: 'Register',
    data() {
      return {
        emailReg: /^[A-Za-z0-9]+([_.][A-Za-z0-9]+)*@([A-Za-z0-9-]+\.)+[A-Za-z]{2,6}$/,
        pwdReg: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,30}$/,
        currentCount: 60,
        timerId: 0,
        isStart: false,
        repeatPwdMsg: '',
        showPwd: false,
        showRepeatPwd: false,
        disabled: true,
        loading: false,
        formData: {
          email: '',
          var_code: '',
          password: '',
          repeat_password: '',
          policy: false,
        },
      };
    },
    computed: {
      getButtonText() {
        return !this.isStart
          ? this.$t('common.countdown.normalText')
          : this.$t('common.countdown.sendText', [this.currentCount]);
      },
      privacyPath() {
        const lang = this.$i18n.locale;
        return `/privacy/${lang}`;
      },
    },
    methods: {
      ...mapActions({
        setUserInfo: 'setUserInfo',
      }),
      onBack() {
        this.$router.replace({ name: 'home' });
      },
      startCount() {
        if (this.isStart || !!this.timerId) {
          return;
        }
        this.isStart = true;
        this.timerId = window.setInterval(() => {
          if (this.currentCount === 1) {
            this.isStart = false;
            window.clearInterval(this.timerId);
            this.timerId = 0;
            this.currentCount = 60;
          } else {
            this.currentCount -= 1;
          }
        }, 1000);
      },
      async onSend() {
        try {
          await this.$refs.form.validate('email');
          const res = await sendEmail(this.formData.email);
          if (res) {
            this.$toast.success(this.$t('system.login.verifySentMsg'));
            this.startCount();
          }
        } catch (error) {
          this.$toast(error.message);
        }
      },
      validateRepeatPwd(value) {
        if (!this.pwdReg.test(value)) {
          this.repeatPwdMsg = this.$t('system.login.passwordValidateMsg');
          return false;
        }
        if (value !== this.formData.password) {
          this.repeatPwdMsg = this.$t('system.login.diffPwdValidateMsg');
          return false;
        }
        return true;
      },
      async onSubmit(values) {
        try {
          this.loading = true;
          const res = await userRegister({ ...values, register_way: 1 });
          if (res && res.user_token) {
            this.$notify({
              type: 'success',
              message: this.$t('system.login.registerSucceed'),
            });
            const { email, user_token } = res;
            this.setUserInfo({
              userEmail: email,
              userToken: user_token,
            });
            this.$router.replace({ name: 'home' });
          }
        } finally {
          this.loading = false;
        }
      },
      onFailed({ errors }) {
        let msg = errors[0].message;
        if (errors[0].name === 'repeat_password') {
          msg = this.repeatPwdMsg;
        }
        this.$toast(msg);
      },
    },
    watch: {
      formData: {
        handler(data) {
          if (data.email && data.var_code && data.password && data.repeat_password && data.policy) {
            this.disabled = false;
          } else {
            this.disabled = true;
          }
        },
        deep: true,
        immediate: true,
      },
    },
  };
</script>
